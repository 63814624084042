* {
    font-family: sans-serif;
}
body {
    background-color: rgb(245, 245, 245);
}
.container {
    background-color: #ccc;
}
.cont-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.cont-standard {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 100px;
}
.cont-login {
}
.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 250px;
    height: 100%;
    background-color: #fff;
    color: white;
    border-radius: 0;
}
.title-db {
    margin-bottom: 30px;
}
.container-db {
    margin-left: 260px;
    padding: 20px 0;
}
.table td {
    height: 40px !important;
}
.user-image {
    height: 50px;
}
